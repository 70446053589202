<template>
  <div id="aboutu">
    <div class="pt-[50px] lg:pt-[150px] 2xl:px-60 flex-container">
      <div class="flex flex-wrap">
        <div data-aos="fade-right" data-aos-duration="500" data-aos-offset="300" class="w-full lg:w-1/2">
          <img src="../assets/aboutUs.png" alt="catra" class="max-w-full mx-auto" />
        </div>
        <div data-aos="fade-left" data-aos-duration="500" data-aos-offset="300" class="w-full mx-5 lg:mx-0 lg:w-1/2">
          <div class>
            <h1 class="mb-10 text-lg text-center font-bold">
              About
              <span class="text-md font-light underline underline-offset-4">Us</span>
            </h1>
          </div>
          <div class="text-justify">
          <h2 class="text-base font-light">CV Sudut Digdaya Harmoni Dengan Nomor Induk Berusaha (NIB) 0902240009003. adalah perusahaan yang bergerak di bidang keamanan digital seperti ( CCTV, ALARM, GPSTRACKER, ABSENSI, ACCES DOOR, SMART DOOR DLL ) Kami adalah penyedia keamanan digital dengan teknologi terkemuka dengan paket pemasangan lengkap untuk melindungi Aset dan keluarga anda dari ancaman cyber dan kejahatan lainnya,Dengan fokus pada inovasi, kualitas, kepuasan, Serta memberikan solusi harmonis bagi pelanggan.</h2>
          <h3 class="mt-5">Bergabunglah dengan Kami hari ini dan hadapi tantangan keamanan digital dengan keyakinan. Hubungi kami sekarang untuk konsultasi gratis dan temukan bagaimana kami dapat membantu Anda mencapai tingkat keamanan yang optimal.</h3>
          </div>
          <!-- <a class="text-abhu font-bold bg-primary py-5 pr-3 rounded-full">
            <span class="font-bold bg-second text-bodas py-5 px-6 rounded-full">1</span> DOAJ Indexing Assistance
          </a>-->
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import "@/assets/tailwind.css";

export default {};
</script>
  
  <style>
</style>