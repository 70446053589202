<template>
  <div id="why" class="pt-[100px] lg:pt-[50px]">
    <div class="bg-why bg-no-repeat bg-contain lg:bg-cover 2xl:px-40 lg:pb-20">
      <div class="text-center pt-20">
        <h1 class="mb-10">
          <span class="text-md font-light underline underline-offset-3 mr-3">Why</span>
          <span class="mb-10 text-lg text-center font-bold">Us ?</span>
        </h1>
      </div>
      <div data-aos="fade-up" data-aos-duration="500" data-aos-offset="500" class="text-center grid justify-items-center grid-cols-2 gap-4 mx-3 lg:mx-0 lg:grid-cols-3 lg:px-15">
        <div
          class="border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary block max-w-[400px] rounded-[20px] shadow-lg bg-bodas bg-opacity-50 mb-10 lg:mb-10 lg:w-[400px] lg:h-[310px]">
          <div class="p-4">
            <img src="../assets/proO.png" alt="catra" class="inline h-[80px] mt-0 mb-1" />
            <h1 class="text-center mb-5 text-md font-bold leading-tight text-hideung">PRODUK ORIGINAL</h1>
            <h2 class="text-justify font-light text-base text-abhu">Kami menyediakan produk dengan merk yang memiliki
              lisensi resmi di Indonesia, produk dengan teknologi
              terbaru dengan fitur terkini untuk mendukung keamanan yang optimal.</h2>
          </div>
        </div>
        <div
          class="border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary block max-w-[400px] rounded-[20px] shadow-lg bg-bodas bg-opacity-50 mb-10 lg:mb-10 lg:w-[400px] lg:h-[310px]">
          <div class="p-4">
            <img src="../assets/garansi.png" alt="catra" class="inline h-[80px] mt-0 mb-1" />
            <h1 class="text-center mb-5 text-md font-bold leading-tight text-hideung">GARANSI RESMI MERK</h1>
            <h2 class="text-justify font-light text-base text-abhu">Dengan produk dari merk yang memiliki lisensi resmi
              Indonesia dengan itu di pastikan memiliki garansi yang sangat mudah untuk di claim.</h2>
          </div>
        </div>
        <div
          class="col-span-3 lg:col-span-1 border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary block max-w-[400px] rounded-[20px] shadow-lg bg-bodas bg-opacity-50 mb-10 lg:mb-10 lg:w-[400px] lg:h-[310px]">
          <div class="p-4">
            <img src="../assets/teknisi.png" alt="catra" class="inline h-[80px] mt-0 mb-1" />
            <h1 class="text-center mb-5 text-md font-bold leading-tight text-hideung">GARANSI JASA TEKNISI</h1>
            <h2 class="text-justify font-light text-base text-abhu">Selain memberikan garansi prpduk, kami memberikan
              garansi jasa teknisi selama 3 bulan, berupa kunjungan gratis ketika produk yang di pasang memiliki kendala
              di kemudian hari, serta gratis perawatan rutin selama waktu garansi masih berlaku.</h2>
          </div>
        </div>
      </div>
      <div data-aos="fade-down" data-aos-duration="500" data-aos-offset="300" class="text-center grid justify-items-center grid-cols-2 gap-4 mx-3 lg:mx-0 lg:grid-cols-4 pb-5">
        <div
          class="border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary block max-w-[400px] rounded-[20px] shadow-lg bg-bodas bg-opacity-50 mb-10 lg:mb-10 lg:w-[300px] lg:h-[360px]">
          <div class="p-4">
            <img src="../assets/harga.png" alt="catra" class="inline h-[80px] mt-0 mb-1" />
            <h1 class="text-center mb-5 text-md font-bold leading-tight text-hideung">HARGA TERJANGKAU</h1>
            <h2 class="text-justify font-light text-base text-abhu">Kami memiliki produk berkualitas, dengan harga
              terjangkau serta memberikan pelayanan terbaik untuk kepuasaan keamanan anda.</h2>
          </div>
        </div>
        <div
          class="border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary block max-w-[400px] rounded-[20px] shadow-lg bg-bodas bg-opacity-50 mb-10 lg:mb-10 lg:w-[300px] lg:h-[360px]">
          <div class="p-4">
            <img src="../assets/prof.png" alt="catra" class="inline h-[80px] mt-0 mb-1" />
            <h1 class="text-center mb-5 text-md font-bold leading-tight text-hideung">TEKNISI PROFESIONAL</h1>
            <h2 class="text-justify font-light text-base text-abhu">Kami memiliki teknisi handal yang profesional dan
              memiliki sertifikat keahlian di bidang nya, pemasangan di jamin rapi, aman dengan memberikan kualitas
              layanan terbaik.</h2>
          </div>
        </div>
        <div
          class="border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary block max-w-[400px] rounded-[20px] shadow-lg bg-bodas bg-opacity-50 mb-10 lg:mb-10 lg:w-[300px] lg:h-[360px]">
          <div class="p-4">
            <img src="../assets/survei.png" alt="catra" class="inline h-[80px] mt-0 mb-1" />
            <h1 class="text-center mb-5 text-md font-bold leading-tight text-hideung">SURVEI LOKASI DAN KONSULTASI
              GRATIS</h1>
            <h2 class="text-justify font-light text-base text-abhu">Kesulitan dalam memilih system keamanan yang cocok
              dan baik untuk kebutuhan anda, maka dari itu hubungi kami hariini.</h2>
          </div>
        </div>
        <div
          class="border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary block max-w-[400px] rounded-[20px] shadow-lg bg-bodas bg-opacity-50 mb-10 lg:mb-10 lg:w-[300px] lg:h-[360px]">
          <div class="p-4">
            <img src="../assets/legal.png" alt="catra" class="inline h-[80px] mt-0 mb-1" />
            <h1 class="text-center mb-5 text-md font-bold leading-tight text-hideung">LEGALITAS JELAS</h1>
            <h2 class="text-justify font-light text-base text-abhu">Kami memperhatikan segala sisi untuk memberikan yang
              terbaik untuk client, Toko yang memiliki izin usaha legal, pelayanan awal yang harmonis, serta pelayanan
              di lapangan yang memenuhi standar yang berlaku, dan tanggung jawab jangka Panjang setelahnya.</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>