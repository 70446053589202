<template>
  <div id="client" class="pb-[100px] lg:pb-0">
    <div data-aos="zoom-in" data-aos-duration="500" data-aos-offset="500" class="mt-12 2xl:px-40 lg:pt-[100px] lg:pb-20">
      <div class="text-center">
        <h1 class="mb-3">
          <span class="text-md font-light mr-3">Our</span>
          <span class=" text-lg text-center font-bold">Client</span>
        </h1>
      </div>
      <div  class="justify-items-center grid grid-cols-3 gap-4 lg:grid-cols-7">
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client1.png" />
        </div>
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client2.png" />
        </div>
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client3.png" />
        </div>
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client4.png" />
        </div>
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client5.png" />
        </div>
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client6.png" />
        </div>
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client7.png" />
        </div>
      </div>

      <div class="justify-items-center grid grid-cols-3 gap-4 lg:grid-cols-5 lg:px-48">
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client8.png" />
        </div>
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client9.png" />
        </div>
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client10.png" />
        </div>
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client11.png" />
        </div>
        <div class="rounded-full border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[30px] lg:h-[60px]" src="../assets/client/client12.png" />
        </div>
      </div>

      <div class="justify-items-center grid grid-cols-3 gap-4 lg:grid-cols-3 lg:px-96">
        <div class="rounded-lg border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[70px] lg:h-[100px]" src="../assets/client/client13.png" />
        </div>
        <div class="rounded-lg border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[70px] lg:h-[100px]" src="../assets/client/Group14.png" />
        </div>
        <div class="rounded-lg border-solid border-4 border-bodas transition duration-300 ease-in-out hover:border-primary">
          <img class="mx-auto h-[70px] lg:h-[100px]" src="../assets/client/client15.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>