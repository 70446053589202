<template>
  <div id="konsul">
    <div class="mx-3 2xl:px-40 lg:pb-20">
      <div data-aos="zoom-in" data-aos-duration="500" data-aos-offset="500" class="text-left grid justify-items-center gap-4 lg:px-15">
        <div class="border-solid border-4 border-primary block max-w-[1100px] rounded-[20px] shadow-lg bg-bodas bg-opacity-50 mb-10 lg:mb-10 lg:w-[1100px] lg:h-[280px]">
          <div class="p-5">
            <h1 class="text-center mb-3 text-lg font-bold leading-tight text-hideung">Bingung Ambil Paket Yang Mana? Mari Konsultasi</h1>
            <div class="text-center mb-3 text-base font-light">
              <h2 class="mb-3">Hubungi admin sekarang untuk konsultasi dan survei <span class="text-primary font-bold">GRATIS</span></h2>
              <h3>Keamanan digital tidak boleh diabaikan, kami siap memberi solusi harmonis perihal paket pemasangan produk,</h3>
              <h4>admin kami akan memberikan yang terbaik untuk kebutuhan anda kedepanya.</h4>
            </div>
          </div>
          <div class="text-center lg:mt-[0px]">
            <a href="https://wa.me/6283131462238"
              class="transition duration-300 ease-in-out hover:bg-primary hover:text-bodas text-primary border-2 border-primary font-bold bg-bodas py-3 px-12 rounded-xl">Konsultasi GRATIS</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>