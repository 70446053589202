<template>
    <div id="service">
        <div data-aos="flip-up" data-aos-duration="500" data-aos-offset="500" class="mx-3 2xl:px-40 lg:pb-20">
            <div class="grid justify-items-center gap-4 lg:px-15">
                <div
                    class="bg-prod bg-center bg-cover border-solid border-4 border-bodas transition duration-300 ease-in-out border-primary block max-w-[1100px] rounded-[20px] shadow-lg bg-bodas bg-opacity-50 mb-10 lg:mb-10 lg:w-[1100px] lg:h-[280px]">
                    <div class="text-center p-5">
                        <h1 class="mb-5">
                            <span class="text-md font-light mr-3">Our Amazing </span>
                            <span class=" text-lg text-center font-bold">Product</span>
                        </h1>
                    </div>
                    <div class="grid justify-items-center grid-cols-3 gap-4 lg:grid-cols-8 lg:px-16 mb-3">
                        <div class="">
                            <img class="mx-auto h-[20px] lg:h-[30px]" src="../assets/product/hilo.png" />
                        </div>
                        <div class="">
                            <img class="mx-auto h-[30px] lg:h-[50px]" src="../assets/product/tpli.png" />
                        </div>
                        <div class="">
                            <img class="mx-auto h-[30px] lg:h-[40px]" src="../assets/product/imo.png" />
                        </div>
                        <div class="">
                            <img class="mx-auto h-[30px] lg:h-[40px]" src="../assets/product/ezv.png" />
                        </div>
                        <div class="">
                            <img class="mx-auto h-[30px] lg:h-[30px]" src="../assets/product/solu.png" />
                        </div>
                        <div class="">
                            <img class="mx-auto h-[30px] lg:h-[40px]" src="../assets/product/conc.png" />
                        </div>
                        <div class="">
                            <img class="mx-auto h-[30px] lg:h-[40px]" src="../assets/product/gpst.png" />
                        </div>
                        <div class="">
                            <img class="mx-auto h-[30px] lg:h-[40px]" src="../assets/product/spc1.png" />
                        </div>
                    </div>
                    <div class="grid justify-items-center grid-cols-2 gap-4 lg:grid-cols-4 lg:px-16">
                        <div class="">
                            <img class="mx-auto h-[30px] lg:h-[50px]" src="@/assets/product/dahua.png" />
                        </div>
                        <div class="">
                            <img class="mx-auto h-[30px] lg:h-[40px]" src="../assets/product/soli.png" />
                        </div>
                        <div class="">
                            <img class="mx-auto h-[20px] lg:h-[30px]" src="../assets/product/hikv.png" />
                        </div>
                        <div class="">
                            <img class="mx-auto h-[20px] lg:h-[50px]" src="../assets/product/wanwa.png" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import "@/assets/tailwind.css";

export default {};
</script>

<style></style>